.terms-box {
  padding: rem-calc(50) 0;

  .terms-wrap {
    padding-bottom: rem-calc(24);
    border-bottom: rem-calc(1) solid rgba($text, 0.6);
    margin-bottom: rem-calc(24);

    &.no-border {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .heading26 {
    font-family: $sfProTextMedium;
    color: $black;
    margin-bottom: rem-calc(20);
  }

  .heading20 {
    font-family: $sfProText;
    color: $black;
    margin-bottom: rem-calc(20);
  }

  .terms-section {
    margin-bottom: rem-calc(24);

    ul {
      padding-left: rem-calc(100);
      margin-bottom: rem-calc(24);

      &.dots-list {
        padding-left: 0;

        li {
          position: relative;
          padding-left: rem-calc(14);

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $black;
          }
        }
      }

      li {
        margin-bottom: rem-calc(8);
        color: $text;

        a {
          color: $red;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }


    .heading18 {
      color: $grayScale-5;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .terms-section-para {
      margin-bottom: rem-calc(16);

      &:last-child {
        margin-bottom: 0;
      }

      .heading16 {
        margin-bottom: rem-calc(16);
        color: $text;

        a {
          color: $red;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


@media (max-width: 991px) {
  .terms-box {

    .terms-wrap {
      padding-bottom: rem-calc(12);
      margin-bottom: rem-calc(12);
    }

    .heading26 {
      margin-bottom: rem-calc(10);
    }

    .heading20 {
      margin-bottom: rem-calc(10);
    }

    .terms-section {
      margin-bottom: rem-calc(12);

      ul {
        padding-left: rem-calc(50);
        margin-bottom: rem-calc(12);

        &.dots-list {
          li {
            position: relative;
            padding-left: rem-calc(12);
          }
        }

        li {
          margin-bottom: rem-calc(6);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .terms-section-para {
        margin-bottom: rem-calc(8);

        &:last-child {
          margin-bottom: 0;
        }

        .heading16 {
          margin-bottom: rem-calc(8);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}