.order-details-box {
  .order-details-heads {
    display: none;
  }

  .order-address-wrap {
    margin-bottom: rem-calc(27);

    .heading14 {
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }
  }

  .order-item-wrap {
    padding-bottom: rem-calc(24);
    padding-top: rem-calc(20);
    border-top: rem-calc(1) solid $grayScale-2;

    &:nth-child(1) {
      padding-top: 0;
      border-top: none;
    }
  }

  .order-total-wrap {
    padding-top: rem-calc(12);
    border-top: rem-calc(1) solid $text;
  }

  .order-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: rem-calc(14);
    }
  }

  .order-details-wrap {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(16);

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .order-details-left {
      width: rem-calc(180);
      height: rem-calc(180);

      .card-img {
        width: 100%;
        height: 100%;
        position: relative;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
      }
    }

    .order-details-right {
      padding-left: rem-calc(20);
      width: calc(100% - rem-calc(180));
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .order-details-discription {
      width: rem-calc(250);

      p {
        font-size: rem-calc(14);
      }
    }

    .order-size-cost {
      width: calc(100% - rem-calc(250));
      padding-left: rem-calc(20);

      .old-price {
        color: $grayScale-1;
        text-decoration: line-through;
        margin-right: rem-calc(10);
      }
    }
  }
}

@media print {
  .header-cntr {
    display: none;
  }

  .wrapper {
    padding: 0;
  }

  .footer-cntr {
    display: none;
  }

  .account-box {
    display: block;
    padding: 0;
  }

  .account-side-nav {
    display: none;
  }

  .account-tab-content {
    width: 100%;
    padding-left: 0;
  }

  .order-details-box {
    padding: 30px;

    .order-details-heads {
      display: block;
      margin-bottom: 15px;
      font-family: $sfProTextMedium;
      position: relative;
      text-align: center;

      img {
        position: absolute;
        left: 0;
      }
    }

    .order-details-wrap {
      .order-details-right {
        flex-wrap: wrap;
      }

      .order-size-cost {
        width: 100%;
        padding-left: 0;
        padding-top: rem-calc(20);
      }
    }

    .orders-top {
      display: none;
    }
  }


}