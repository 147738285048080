.sales-tax-form-upload-modal .modal-content {
  border-radius: 30px;
  padding: 25px;
}
.sales-tax-form-upload-modal .modal-header {
  border-bottom: none;
}
.sales-tax-form-upload-modal .modal-footer {
  border-top: none;
}
.sales-tax-form-upload-modal h3 {
  font-family: 'SF Pro Text';
  font-size:2rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #000;
}
.sales-tax-form-upload-modal h5{
  font-family: 'SF Pro Text';
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #000;
}
.sales-tax-form-upload-modal .formbold-drop-file{
  font-family: 'SF Pro Text';
  font-weight: 400;
  color: #6b6a6a;
}
.formbold-mb-5 {
  margin-bottom: 20px;
}
.formbold-pt-3 {
  padding-top: 12px;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 70px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.formbold-form-wrapper {
  margin: 0 auto;
  // width: 350px;
  width: 100%;
  min-height: 300px;
  /* width: 100%; */
  background: white;
}
.formbold-form-wrapper .form-body {
  background-color: white;
  // padding: 5px 25px;
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.file-name-preview {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 4px solid #e0e0e0;
}
.formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #6a64f1;
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.item-center {
  align-items: center;
}
.justify-end {
  justify-content: end;
}
.basis-2 {
  flex-basis: 50%;
}
.break-all {
  word-break: break-all;
}
.z-10 {
  z-index: 10;
}

.formbold-file-input input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 300px;
  height: 200px;
}

.formbold-file-input label {
  position: relative;
  border: 3px solid rgb(185, 178, 178);
  border-radius: 20px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}
.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px 32px;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.formbold-file-name {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  padding-right: 12px;
}
.formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background: #e2e5ef;
}

.formbold-progress {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0;
  top: 0;
  background: #6a64f1;
  border-radius: 8px;
}
