.custom-form-check {
  &.form-check {
    position: relative;
    padding-left: 0;

    .form-check-label {
      display: inline-block;
      padding: rem-calc(8) rem-calc(24);
      border-radius: rem-calc(50);
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      border: rem-calc(1) solid rgba($black, 0.12);
      background-color: $white;
      position: relative;
      z-index: 1;
      cursor: pointer;
    }

    .form-check-input {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      opacity: 0;

      &:checked {
        ~.form-check-label {
          background-color: rgba($blueScale-2, 0.05);
          border-color: $blueScale-2;
        }
      }
    }
  }
}

.custom-radio {
  &.form-check {
    position: relative;

    span {
      color: $grayScale-1;
    }

    .form-check-input {
      width: 18px;
      height: 18px;
      border: 1px solid $grayScale-1;

      &:focus {
        box-shadow: none;
      }

      &:checked[type='radio'] {
        background-image: none;
        background-color: transparent;
        border: 2px solid $blueScale-2;

        &:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 1px;
          width: 16px;
          height: 16px;
          background-color: $blueScale-2;
          border: 2px solid $white;
          border-radius: 50%;
        }

      }
    }
  }
}


.custom-number-wrap {
  .PhoneInput {
    .PhoneInputCountry {
      margin-right: rem-calc(10);
    }

    .PhoneInputCountryIcon {
      width: rem-calc(28);
      height: rem-calc(28);
      box-shadow: none;

      .PhoneInputCountryIconImg {
        background-color: $white;
      }
    }

    .PhoneInputInput {
      padding: 0.375rem 0.75rem;
      font-size: rem-calc(16);
      font-weight: 400;
      line-height: 1;
      color: $text;
      background-color: $white;
      background-clip: padding-box;
      border: rem-calc(1) solid $grayScale-2;
      appearance: none;
      border-radius: rem-calc(6);

      &:focus {
        color: $text;
        background-color: $white;
        border-color: $grayScale-2;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }
    }
  }
}