.custom-tabs {
  .nav-tabs {
    border-bottom: 0;
    justify-content: center;

    .nav-item {
      margin-right: rem-calc(16);

      &:last-child {
        margin-right: 0;
      }
    }

    .nav-link {
      padding: rem-calc(6);
      font-size: rem-calc(20);
      font-family: $merriweather;
      font-weight: $bold;
      border: 0;
      color: $text;
      border-bottom: rem-calc(2) solid transparent;

      &.active {
        border-bottom-color: $red;
      }
    }
  }

  .tab-content {
    padding: rem-calc(16) 0 0;
  }
}

@media (min-width: 768px) {
  .custom-tabs {
    .tab-content {
      padding: rem-calc(30);
    }
  }
}