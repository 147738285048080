.header-login {
  .login {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: rem-calc(10);

      &:first-child {
        margin-left: rem-calc(0);
      }
    }
  }
}

.chat {
  position: fixed;
  left: rem-calc(20);
  bottom: rem-calc(20);
  background-color: $text;
  z-index: 100;

  a {
    padding-top: rem-calc(3);
    color: $white;
    font-size: rem-calc(25);
  }
}

.thumb-box {
  position: relative;
  margin: rem-calc(24) 0;

  img {
    width: 100%;
  }

  .product-type {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .shop-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: rem-calc(45);
      color: $white;
      font-family: $merriweather;
      font-weight: 900;
    }
  }
}

@media (max-width: 991px) {
  .header-after-login {
    .brand-logo {
      text-align: right;
    }
  }

  .after-login {
    .login {
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;

      .login-item {
        margin-left: 0;

        .item {
          padding: rem-calc(8) rem-calc(6);
          display: block;
        }
      }
    }
  }

  .thumb-box {
    margin-bottom: 0;

    .product-type {
      .shop-link {
        font-size: rem-calc(30);
      }
    }
  }
}