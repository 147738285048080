.checkout-modal {
  top: rem-calc(127);
  max-height: calc(100vh - 127px);
}

.checkout-modal-dialog {
  max-width: rem-calc(635);
  margin: 0;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  border-radius: 0;

  .modal-content {
    border-radius: rem-calc(4);
  }
}

.checkout-backdrop {
  top: rem-calc(127);
}

@media(max-width: 767px) {
  .checkout-modal {
    top: rem-calc(131);
    max-height: calc(100vh - 84px);
  }

  //.checkout-modal-dialog{
  //  top: rem-calc(84);
  //}
  .checkout-backdrop {
    top: rem-calc(84);
  }
}

.checkout-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #000;
  border: 0;
  font-size: rem-calc(14);
  padding: 0;

  &:hover,
  &:active,
  &.btn:first-child {
    color: inherit;
    color: #000;
  }
}


.checkout-address-wrap {
  padding-top: rem-calc(24);
}

.checkout-details {
  border: rem-calc(1) solid $grayScale-2;
  border-radius: rem-calc(4);
  padding: rem-calc(20) rem-calc(40);

  .continueToOrder {
    width: 100%;
    padding: 9px;
    margin-bottom: rem-calc(19);
  }

  .continue-msg {
    font-size: rem-calc(15);
    line-height: rem-calc(25);
    display: inline-block;
    padding-bottom: rem-calc(10);
    border-bottom: rem-calc(1) solid $grayScale-2;

    span {
      font-weight: 600;
    }
  }
}

.order-summary-wrap {
  margin-top: rem-calc(16);
  margin-bottom: rem-calc(10);
  border-bottom: rem-calc(1) solid $grayScale-2;

  h5 {
    text-align: center;
    font-family: $sfProText;
    margin-bottom: rem-calc(22);
    color: $text;
    font-weight: 400;
  }

  .price-breakup {
    margin-bottom: rem-calc(6);
  }

}

.sub-total-wrap {
  margin-top: rem-calc(9);

  p {
    font-weight: 700;
  }
}


.added-prod-details {
  h4 {
    font-family: $sfProText;
  }
}

.product-cart-wrap {

  .product-img {
    width: 100%;
    max-width: rem-calc(175);
    height: auto;
    object-fit: contain;
    margin-right: rem-calc(13);
  }


}


.added-prod-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: rem-calc(24);
  padding-left: rem-calc(12);

  .old-price {
    color: $grayScale-1;
    text-decoration: line-through;
  }

  .product-controls {
    color: $grayScale-1;
    position: relative;
    padding-right: rem-calc(10);
    margin-right: rem-calc(10);
    font-size: rem-calc(14);

    &:hover {
      color: $grayScale-1;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: rem-calc(1);
      background-color: $grayScale-1;
    }

    &:nth-last-child(1) {
      &:after {
        content: none;
      }
    }
  }
}

.checkout-wrap {
  display: flex;
  padding: 0 rem-calc(30);

  .checkout-left {
    width: calc(100% - rem-calc(336));
    padding-right: rem-calc(10);
  }

  .checkout-right {
    width: rem-calc(336);
  }
}

.quick-link-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -9px;

  .quick-link-item {
    padding: rem-calc(9);
  }

  .quick-link-btn {
    padding: rem-calc(15) rem-calc(50);
    width: 100%;

    max-width: 196px;
    text-align: center;
    border: 1px solid $grayScale-5;
    border-radius: 4px;
  }
}


.order-history-wrap {
  padding-top: 61px;
}

.shipping-calc {
  margin-top: rem-calc(20);
  color: $grayScale-1;
  text-align: center;
  font-size: rem-calc(14);
}

.po-number-wrap {
  display: flex;
  align-items: center;
  margin-bottom: rem-calc(16);

  label {
    display: inline-block;
    margin-right: rem-calc(9);
    color: $blueScale-2;
    font-size: rem-calc(16);
    font-weight: 700;
    line-height: rem-calc(25);
  }

  input {
    max-width: rem-calc(430);
    padding: rem-calc(4) rem-calc(10);
    border-radius: rem-calc(4);
    border: rem-calc(1) solid $grayScale-2;
  }
}

.continue-payment-top {
  padding-bottom: rem-calc(15);
  margin-bottom: rem-calc(6);
  border-bottom: rem-calc(1) solid $grayScale-2;

}

.continue-payment-bot {
  border-top: rem-calc(1) solid $grayScale-2;
  padding-top: rem-calc(18);
}

.continue-payment-location {
  padding-bottom: rem-calc(19);
  border-bottom: rem-calc(1) solid $grayScale-2;

  &:nth-last-child(1) {
    // padding-bottom: 0;
    border-bottom: none;
  }

  .editbtn {
    color: $grayScale-1;
    text-decoration: underline;
    border: none;

    &:hover,
    &:focus {
      color: $grayScale-1;
      border: none;
    }
  }
}


.prod-location-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -16px -8px 0;

  .prod-location-col {
    padding: rem-calc(16) rem-calc(8) 0;
    width: 25%;

    .prod-location-item {
      padding: rem-calc(13) rem-calc(20) rem-calc(15);
      border: rem-calc(1) solid $grayScale-2;
      border-radius: rem-calc(4);

      .product-img {
        max-height: rem-calc(187);
        height: rem-calc(187);
        width: 100%;
        background-color: #f6f5f4;
        margin-bottom: rem-calc(8);

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .product-text {
        font-size: rem-calc(14);

        span {
          display: inline-block;
        }

        .old-price {
          text-decoration: line-through;
          color: $grayScale-1;
          margin-left: rem-calc(6);
        }
      }
    }
  }
}

.checkout-table-sm {
  &.striped-table {
    width: 100%;
    table-layout: fixed;

    tr {
      td {
        padding: 0;

        .dropdown {
          margin-left: 0;
        }
      }

      .dropdown {
        padding-top: rem-calc(24);
        margin: rem-calc(6) rem-calc(24);

      }
    }
  }
}

.had-close-btn {
  position: relative;

  .dropdown {
    max-width: calc(100% - rem-calc(44));
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: rem-calc(8);
    width: rem-calc(28);
    height: rem-calc(28);
    padding: 0;

    &:hover,
    &:focus {
      color: $red;
    }
  }
}

.checkout-table-wrap {
  h6 {
    display: none;
  }


  tr {
    &:nth-child(1) {
      .had-close-btn {
        .clear-btn {
          top: rem-calc(48);
        }
      }

      h6 {
        display: block;
      }

      td {
        padding-top: 0;
      }
    }

    &:nth-last-child(1) {
      td {
        padding: 0 !important;
      }
    }
  }
}


@media (max-width: 991px) {
  .prod-location-wrap {
    .prod-location-col {
      width: 50%;
    }
  }
}

.sizes-total {
  padding-top: 20px;
  display: inline-block;
}

.add-address-btn {
  word-break: break-word;
}