.footer-cntr {
    border-top: rem-calc(1) solid $grayScale-1;
    margin-top: rem-calc(58);
}
.footerBox {
    padding: rem-calc(50) rem-calc(20);
}
.footer-logo {
    display: flex;
    h4 {
        padding-left: rem-calc(50);
        font-weight: 800;
    }
}
.block-links-wrap {
    display: flex;
    justify-content: flex-end;
    .block-links {
        padding-left: rem-calc(30);
        &:first-child {
            padding-left: rem-calc(0);
        }
        li {
            &:first-child {
                a {
                    color: $grayScale-1;                    
                }
            }
            a {
                @extend %font12;
                line-height: rem-calc(21);
            }
        }
    }
}
.rights-reserve {
    background-color: $grayScale-4;    
    padding: rem-calc(12) rem-calc(15);
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        @extend %font12;
        letter-spacing: 0.1em;
    }
}
.footer-link {
    display: flex;
    align-items: center;
    padding-left: rem-calc(20);
    li {
        border-right: rem-calc(1) solid $text;
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        &:last-child {
            border-right: 0;
        }
        a {
            @extend %font12;
            display: block;
            line-height: rem-calc(12);
            letter-spacing: 0.1em;
        }
    }
}

@media (max-width: 767px) {
    .footer-cntr {
        margin-top: rem-calc(15);
    }
    .footerBox {
        padding: rem-calc(30) rem-calc(12);
    }
    .block-links-wrap,
    .footer-logo {
        justify-content: center;
    }
    .footer-logo {
        h4 {
            padding-left: 2rem;
            font-size: 1rem;
            line-height: 1.5;
            br {
                display: none;
            }
        }
    }
    .block-links-wrap {
        padding-top: rem-calc(30);
    }
    .rights-reserve {
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
    }
    .footer-link {
        padding-left: 0;
        padding-top: rem-calc(10);
        li {
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
        }
    }
}