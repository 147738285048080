.custom-dropdown {
  &.nav-dropdown {
    max-width: rem-calc(250);

    .dropdown-toggle {
      width: 100%;
      padding: 0.1875rem 0.5rem;
    }

    .dropdown-menu {
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .dropdown-toggle {

    &:hover,
    &:active {
      color: inherit;
    }

    &.show {
      color: inherit;
    }

    &::after {
      display: none;
    }
  }

  &[placement*="bottom-start"] {
    .custom-menu {
      &[x-placement*="bottom-start"] {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: rem-calc(-3)
      }
    }

    .toggle-btn {
      &.show {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

}

.toggle-btn {
  font-size: rem-calc(17);
  line-height: rem-calc(22);
  padding: rem-calc(8) rem-calc(24) rem-calc(8) rem-calc(8);
  font-weight: 400;
  font-family: 'SF Pro Text';
  text-align: left;
  position: relative;

  &.btn-outline-secondary {
    border-color: $grayScale-1;
    color: $text;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      color: $text;
      border-color: $text;
      background-color: $white;
    }

    &:disabled {
      color: $grayScale-1;
      border-color: $text;
      background-color: $white;
    }
  }

  &.show {
    .icon-chevron-down {
      rotate: (180deg);
      transform: translateY(50%);
    }
  }
}

.select-right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: rem-calc(8);

  &.icon-chevron-down {
    font-size: rem-calc(20);
  }
}

.custom-menu {
  border-color: $text;
  min-width: 100%;

  .dropdown-item {
    &:hover {
      background-color: $grayScale-3;
    }

    &:active {
      background-color: $grayScale-3;
      color: $text;
    }
  }
}

.outline-dropdown {
  &.dropdown {
    .dropdown-toggle {
      width: 100%;
      text-align: left;
      border: rem-calc(1) solid $grayScale-2;
      padding-right: rem-calc(24);

      &:after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .dropdown-menu {
      width: 100%;
      max-height: rem-calc(300);
      overflow-y: auto;
    }

    .dropdown-item {
      white-space: initial;
      border-bottom: rem-calc(1) solid $grayScale-2;

      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}