.outerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 2;
}

.formInner {
  margin-top: -170px;
}

.innerWrap {
  border-radius: 10px;
  max-height: 250px;
  height: 100%;
  max-width: 400px;
  width: 100%;
  padding: 20px 30px;
  box-shadow: 10px 6px 25px 0px rgba($color: #000000, $alpha: 0.3);
  background-color: #fff;
  z-index: 1;
}

.logoWrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.logo {
  height: 60px;
  width: auto;
}

.formTitle {
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
}

.formSubTitle {
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin-top: 3px;
}

.formWrap {
  width: 100%;
}

.formControl {
  display: block;
  margin-bottom: 15px;
  position: relative;
  width: 300px;

  i {
    display: none;
  }

  input {
    display: block;
    border: 1px solid #8c8c8c;
    border-radius: 0.4rem;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    outline: none;
    font-family: 'Inter', sans-serif;
    &:focus {
      border: 1px solid #000;
    }
  }



  &.password {
    input {
      padding-right: 35px;
    }
    [class^='icon-'],
    [class*=' icon-'] {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &.error {
    input {
      border-color: #ff0000;
      padding-right: 30px;
    }
    i {
      position: absolute;
      display: block;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      line-height: 16px;
      color: #ff0000;
    }
  }
}

.submitBtn {
  background-color: #096fba;
  color: #fff;
  padding: 2px 10px;
  border: 1px solid #096fba;
  border-radius: 8px;
  font-size: 13px;
  line-height: 21px;
  padding: 5px 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  // box-shadow: none;
  // outline: none;
}

.btnWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.link {
  border: none;
  background: none;
  outline: none;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}
