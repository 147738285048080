@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?ibe3jl');
  src: url('../fonts/icomoon.eot?ibe3jl#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ibe3jl') format('truetype'),
    url('../fonts/icomoon.woff?ibe3jl') format('woff'),
    url('../fonts/icomoon.svg?ibe3jl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-store:before {
  content: "\e915";
}

.icon-share-link:before {
  content: "\e914";
}

.icon-eye-blocked:before {
  content: "\e912";
}

.icon-eye:before {
  content: "\e913";
}

.icon-three-bars:before {
  content: "\e910";
}

.icon-stars:before {
  content: "\e911";
}

.icon-lock-alt:before {
  content: "\e90e";
}

.icon-msg-alt:before {
  content: "\e90f";
}

.icon-mail:before {
  content: "\e90d";
}

.icon-location-alt:before {
  content: "\e903";
}

.icon-mouse:before {
  content: "\e90c";
}

.icon-calendar:before {
  content: "\e90b";
}

.icon-green-check .path1:before {
  content: "\e909";
  color: rgb(2, 3, 2);
}

.icon-green-check .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(65, 180, 74);
}

.icon-lock:before {
  content: "\e906";
}

.icon-chevron-down:before {
  content: "\e908";
}

.icon-location:before {
  content: "\e904";
}

.icon-filter:before {
  content: "\e905";
}

.icon-envelop:before {
  content: "\e902";
}

.icon-chat:before {
  content: "\e900";
}

.icon-food:before {
  content: "\e901";
}

.icon-cross:before {
  content: "\e907";
}