.about-us-box {
  margin: rem-calc(47);

  .about-us-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .about-us-img {
    width: rem-calc(580);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .about-us-right {
    width: calc(100% - rem-calc(580));
    padding-left: rem-calc(47);

    img {
      margin-top: rem-calc(10);
    }

    .about-us-para {

      h1,
      h2 p {
        font-family: $sfProText;
        font-weight: $regular;
        color: $text;
      }

      h1 {
        font-family: $merriweather;
        font-size: rem-calc(41);
        margin-bottom: rem-calc(21);
      }

      p,
      h2 {
        color: $text;
        font-weight: $regular;
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(18);
        font-family: $sfProText;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

    }

  }
}


@media (max-width: 1199px) {
  .about-us-box {

    .about-us-img {
      width: rem-calc(280);
    }

    .about-us-right {
      width: calc(100% - rem-calc(280));
      padding-left: rem-calc(27);

    }
  }
}


@media (max-width: 991px) {
  .about-us-box {
    padding-top: rem-calc(40);

    .about-us-img {
      width: 100%;
      height: rem-calc(300);
      margin-bottom: rem-calc(24);
    }

    .about-us-right {
      width: 100%;
      padding-left: 0;

      .about-us-para {

        h2 {
          font-size: rem-calc(35);
          margin-bottom: rem-calc(16);
        }

        p {
          font-size: rem-calc(16);
          margin-bottom: rem-calc(12);

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

      }

    }
  }
}