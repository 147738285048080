.btn-light-primary {
  &.btn {

    color: $white;
    background-color: $blueScale-2;

    &:hover,
    &:focus {
      color: $white;
      background-color: $blueScale-2;
    }
  }

}

.btn-grey {
  &.btn {

    color: $grayScale-1;
    background-color: $transparent;
    border: none;

    &:hover,
    &:active,
    &:focus {
      color: $grayScale-1;
      background-color: $transparent;
    }
  }

}

.btn-outline-secondary {
  &.btn {
    min-width: auto;
    background-color: $white;
    border: 1px solid $grayScale-2;
    color: $text;

    &:focus,
    &:active,
    &:hover {
      background-color: $white !important;
      border: 1px solid $grayScale-2;
      color: $text !important;
    }
  }
}

.btn {
  &.btn-md {
    padding: 4px 8px;
    font-size: 14px;
  }
}


.dropdown-select {
  &.btn {
    color: $text;
    border-color: $text;
    background-color: $white;
    padding-right: rem-calc(32);
    width: 100%;
    position: relative;
    line-height: 1;
    text-align: left;

    &:after {
      content: "\e908";
      font-family: "icomoon";
      position: absolute;
      right: 0.625rem;
      top: 50%;
      transform: translateY(-50%) rotate(0);
      font-size: 1.5rem;
      color: #C71E08;
      background: none;
      border: none;
    }

    &.show {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &:hover,
    &:focus {
      color: $text;
      border-color: $text;
      background-color: $white;
    }

    &~.dropdown-menu {
      width: 100%;
      min-width: auto;
      padding: 0;
      border: rem-calc(1) solid $grayScale-1;
      border-radius: rem-calc(4);
      max-height: rem-calc(170);
      overflow-y: auto;
      overflow-x: hidden;

      .dropdown-item {
        padding: rem-calc(5) 0;
        padding: rem-calc(7) rem-calc(12);
        line-height: 1;
        display: block;
        width: 100%;
        white-space: normal;
        border-bottom: rem-calc(1) solid $grayScale-2;

        &:nth-last-child(1) {
          border-bottom: none;
        }

        &.new-address {
          border-top: rem-calc(1) solid $grayScale-1;
        }

        &:hover,
        &:focus,
        &.active {
          background-color: $grayScale-3;
          color: inherit;
        }

      }
    }
  }
}