.we-work-box {
    text-align: center;
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
    .heading-section,
    .content-wrap {
        padding-bottom: rem-calc(40);
    }
    .block-title {
        padding-bottom: rem-calc(30);
        &.has-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            [class^="icon-"], [class*=" icon-"] {
                font-size: rem-calc(31);
                margin-right: rem-calc(7);
            }
        }
    }
    p {
        font-family: $sfProTextMedium;
    }
}

.heading-section {
    [class^="icon-"],
    [class*=" icon-"] {
        margin-left: 4px;
        color: #dc3545;
        cursor: pointer;
        display: inline-block;
    }
}

.alternative-content {
    background-color: $grayScale-4;
    position: relative;
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(40);
    @extend %listRedDot;
    .heading-section {
        padding-bottom: rem-calc(30);
    }
    .alternative {
        padding-left: rem-calc(70);
        padding-right: rem-calc(70);
        z-index: 2;
        position: relative;
    }
    .content-block {
        padding-top: rem-calc(90);
        padding-bottom: rem-calc(90);
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        height: rem-calc(75);
        width: 100%;
        background-color: $white;
    }    
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }
    > div {
        > div {
            align-items: center;
        }
    }
    &:nth-child(even) {
        > div {
            > div {
                flex-direction: row-reverse;
            }
        }
    }
}

@media (max-width: 1199px) {
    .alternative-content {
        .alternative {
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
        }
    }
}
@media (max-width: 991px) {
    .alternative-content {
        .content-block {
            padding-top: rem-calc(30);
            padding-bottom: rem-calc(30);
        }
        .media-holder {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .alternative-content {
        &:before {
            height: rem-calc(30);
        }       
        &:after {
            display: none;
        }
    }
}
@media (max-width: 767px) {
    .we-work-box {
        .block-title {
            padding-bottom: rem-calc(15);
        }
        .content-wrap {
            padding-bottom: 0;
        }
        .content-block {
            padding-bottom: rem-calc(30);
        }
    }
}