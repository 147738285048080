.custom-accordian {
  &.accordion {
    margin-bottom: rem-calc(18);

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .accordion-item {
      border: none;
    }

    .accordion-button {
      border: rem-calc(1) solid $text;
      padding: rem-calc(14) rem-calc(20);
      font-family: $sfProText;
      font-size: rem-calc(20);
      line-height: rem-calc(24);
      background-color: white;
      color: $text;
      border-bottom-color: $grayScale-1;
      box-shadow: none;

      &:after {
        content: "\e908";
        font-family: 'icomoon';
        position: absolute;
        right: rem-calc(10);
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        font-size: rem-calc(24);
        color: $red;
        background: none;
      }

      &.collapsed {
        border-bottom: rem-calc(1) solid;
        border-color: $grayScale-1;

        &:focus {
          border-bottom: rem-calc(1) solid $grayScale-1;
        }

        &:after {
          transform: translateY(-50%) rotate(0deg);
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    .accordion-collapse {
      border-left: rem-calc(1) solid $grayScale-1;
      border-right: rem-calc(1) solid $grayScale-1;
      border-bottom: rem-calc(1) solid $grayScale-1;

      &.show {
        border-left: rem-calc(1) solid $text;
        border-right: rem-calc(1) solid $text;
        border-bottom: rem-calc(1) solid $text;

      }
    }

    .accordion-body {
      padding: rem-calc(24);

    }
  }
}