.details-card {
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: $grayScale-4;
  border-color: $grayScale-4;

  .card-body {
    padding: rem-calc(60) rem-calc(24);
  }

  .card-img {
    height: rem-calc(486);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .card-img-top {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    display: block;
    width: 100%;
  }

  .card-img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loader-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.heading1 {
  font-size: rem-calc(20);
  font-weight: 700;
  color: $text;
  line-height: rem-calc(24);
}

.price {
  display: inline-block;
  min-width: 4rem;
}

.selected-size {
  &.open {
    .icon-chevron-down {
      rotate: (180deg);
      transform: translateY(50%);
    }
  }
}

.gray-box {
  background-color: $grayScale-4;
  padding: rem-calc(24);
}

.tab-spacing {
  padding: 0 rem-calc(24);
}

.heading20 {
  color: $text;
  font-size: rem-calc(20);
  line-height: rem-calc(24);
}

.tab-info-box {
  @extend %listRedDot;

  ul {
    li {
      padding-bottom: 0;
    }
  }
}

.fit-img-box {
  padding: rem-calc(24);
  background-color: $grayScale-4;
}

.product-size-box {
  .heading18 {
    color: $black;
    font-weight: 400;
  }

  .quanitities-wrap {
    display: flex;
    align-items: center;
    margin: -14px -7px 0;
    flex-wrap: wrap;
    margin-bottom: 21px;
  }

  .quanitities-row {
    padding: 14px 7px 0;
  }

  .quanitities-col {
    display: flex;
    flex-direction: column;
    align-items: center;

    .quanitities-title {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .quanitities-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $black;
    padding: 3px 4px;
    width: rem-calc(55);
    height: rem-calc(55);

    .form-control {
      width: 100%;
      border-radius: 0;
      border: none;
      padding: 0;
      text-align: center;
      box-shadow: none;

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }
    }

    .count {
      font-size: 16px;
    }

    span {
      color: $grayScale-1;
      font-size: 14px;
    }
  }

  .product-infos {
    padding-top: rem-calc(24);

    .product-row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      color: $black;
      margin: 0 -16px;
    }

    .product-col {
      width: 33.33%;
      padding: 0 16px;

      &:last-child {
        .product-item {
          border-right: none;
        }
      }
    }

    .product-item {
      width: 100%;
      border-right: 1px solid $grayScale-2;

      .heading14 {
        color: $black;
        font-weight: 400;
      }

      p {
        span {
          color: $grayScale-2;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .details-top {
    // height: calc(608px - 100px);
    // overflow: hidden;
    // overflow-y: auto;
  }

  .gray-box {
    padding: rem-calc(50) rem-calc(130);
  }

  .tab-spacing {
    padding: 0 rem-calc(50);
  }

  .fit-img-box {
    padding: rem-calc(24) rem-calc(60);
  }
}

.card-img-hover-link {
  position: relative;
  height: 100%;
  width: 100%;

  .full-size-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.front-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 75px);
  height: calc(100% - 75px);
  z-index: 2;

  &.img-lg {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
  }
}

.back-img {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 75px);
  height: calc(100% - 75px);
  z-index: 1;

  &.img-lg {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
  }
}
