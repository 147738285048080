* {
  margin: 0;
  padding: 0;
}

body,
html {
  color: $text;
  min-height: 100%;
  height: 100%;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $sfProText;
  font-size: rem-calc(16);
  line-height: rem-calc(24);
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-weight: normal;
}

.wrapper {
  min-height: 100%;
  position: relative;
  // overflow: hidden;
  padding-top: rem-calc(127);
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  height: 610px;
}

.pagination {
  flex-wrap: wrap;

  .page-item {
    margin: 2px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

strong {
  font-weight: $bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $merriweather;
  color: $blueScale-1;
  font-weight: 700;
}

.white-color {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li,
  i,
  span,
  div,
  sub,
  sup {
    color: $white;
  }
}

ul>li,
ol>li {
  margin-bottom: 0rem;
}

h1 {
  font-size: rem-calc(50);
  line-height: rem-calc(60);
}

h2 {
  font-size: rem-calc(45);
  line-height: rem-calc(54);
}

h3 {
  font-size: rem-calc(36);
  line-height: rem-calc(44);
}

h4 {
  font-size: rem-calc(24);
  line-height: rem-calc(32);
}

h5 {
  font-size: rem-calc(20);
  line-height: rem-calc(24);
}

h6 {
  font-size: rem-calc(16);
  line-height: rem-calc(20);
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0;
  list-style-type: none;
}

a,
.link,
.btn-link {
  text-decoration: none;
  color: $text;

  &:hover {
    text-decoration: none;
    color: $text;
  }
}

.close-dropdown {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
}

/* bg */
.transparent {
  background-color: $transparent !important;
}

.blueScale-1 {
  background-color: $blueScale-1 !important;
}

.blueScale-2 {
  background-color: $blueScale-2 !important;
}

.red {
  background-color: $red !important;
}

.grayScale-1 {
  background-color: $grayScale-1 !important;
}

.grayScale-2 {
  background-color: $grayScale-2 !important;
}

.grayScale-3 {
  background-color: $grayScale-3 !important;
}

/* buttons */
.btn-outline-blue {
  border-color: $grayScale-1;
}

.custom-container-fluid {
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);
}

.circle {
  &.circle-58 {
    @include size(rem-calc(58));
  }
}

.main-lable {
  color: $grayScale-1;
  font-size: rem-calc(14);
  font-weight: $medium;
  margin-bottom: 7px;
}

.logo-selection {
  pointer-events: auto;
  margin-right: rem-calc(16);
  margin-bottom: rem-calc(16);
  border-radius: rem-calc(4);
  border: rem-calc(2) solid $grayScale-2;
  cursor: pointer;
  padding: 7px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border-color: $text;
  }
}

.section-para {
  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-dots {
  margin-top: 8px;

  li {
    padding-left: 24px;
    position: relative;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      left: 12px;
      top: 9px;
      border-radius: 50%;
      background-color: #000;
    }
  }
}

.list-numbers {
  margin-top: 8px;

  li {
    position: relative;
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.dropdown-size-scale {
  border: rem-calc(1) solid $grayScale-1;
  border-radius: rem-calc(4);

  &.open {
    .selected-size {
      border-bottom: rem-calc(1) solid $grayScale-1;

      &:before {
        transform: rotate(-180deg);
      }
    }
  }
}

.selected-size {
  position: relative;
  padding-right: rem-calc(40);

  &:before {
    content: '';
    position: absolute;
    right: rem-calc(10);
    top: 50%;
    margin-top: rem-calc(-14);
    height: rem-calc(28);
    width: rem-calc(28);
    background-repeat: no-repeat;
    background-size: rem-calc(28);
    background-position: center center;
  }

  .choose-sizes {
    cursor: pointer;
    border-bottom: 0;
  }
}

.scale-dropdown {
  padding-top: rem-calc(20);
  background-color: $white;
  border-radius: rem-calc(4);

  .list-item {
    &:last-child {
      .choose-sizes {
        border-bottom: 0;
      }
    }

    &.active,
    &:hover {
      .choose-sizes {
        background-color: $grayScale-3;
      }
    }
  }

  .list-label {
    display: block;
    padding: 0 rem-calc(14);
    font-family: $sfProTextMedium;
  }

  .choose-sizes {
    cursor: pointer;
  }
}

.choose-sizes {
  padding: rem-calc(12) rem-calc(14);
  border-bottom: rem-calc(1) solid $white;

  .itmes {
    display: flex;
  }

  .item-lable,
  .size-type,
  .item-qty {
    font-size: rem-calc(16);
    line-height: rem-calc(21);
  }

  .item-lable,
  .item-qty {
    color: $text;
  }

  .size-type {
    color: $grayScale-1;
  }

  .size {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: rem-calc(36);
    border: rem-calc(1) solid $grayScale-1;
    border-radius: rem-calc(4);
    margin-right: rem-calc(5);

    &:last-child {
      margin-right: 0;
    }
  }

  .has-action {
    align-items: center;
    display: flex;

    .action-list {
      padding-left: rem-calc(10);
      margin-left: auto;
      padding-left: rem-calc(10);

      .action-list-item {}
    }
  }
}

.action-list {
  display: flex;

  .action-list-item {
    margin-right: rem-calc(5);
    padding-right: rem-calc(5);
    border-right: rem-calc(1) solid $grayScale-1;

    a {
      display: block;
      font-size: rem-calc(14);
      line-height: rem-calc(12);
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }
  }
}

.my-size-scale {
  padding: rem-calc(14) rem-calc(14);

  h6 {
    font-size: rem-calc(16);
    padding-bottom: rem-calc(7);
    margin-bottom: rem-calc(25);
    border-bottom: rem-calc(1) solid $grayScale-1;
    color: $text;
    font-family: $sfProTextMedium;
  }

  .choose-sizes {
    padding: 0;
    cursor: unset;

    &:hover,
    &.active {
      background-color: transparent;
    }
  }

  .add-new {
    a {
      font-size: rem-calc(16);
      color: $text;
      text-decoration: underline;
    }
  }
}

.border-primary {
  color: $blueScale-2 !important;
}

.custom-modal {
  &.modal-dialog-sm {
    .modal-dialog {
      max-width: rem-calc(300);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-close {
    background: none;
    position: absolute;
    right: 100%;
    margin-right: rem-calc(25);
    top: rem-calc(5);
    color: $white;
    opacity: 1;

    &:focus,
    &:hover {
      box-shadow: none;
      border: 0;
    }
  }

  .modal-header {
    padding: rem-calc(34) rem-calc(28) rem-calc(14);
    border-bottom: 0;
  }

  .modal-title {
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    font-weight: 600;
    color: $text;
    font-family: $merriweather;
  }

  .modal-body {
    padding: rem-calc(14) rem-calc(28) rem-calc(14);

    .form-control {
      font-size: rem-calc(16);
      height: rem-calc(29);
      padding: rem-calc(4);
      border: rem-calc(1) solid $grayScale-5;
    }

    .choose-sizes {
      padding-left: 0;
      padding-right: 0;
      padding-top: rem-calc(28);

      .size {
        padding: 0 rem-calc(4) rem-calc(7);
      }

      .item-qty {
        border: 0;
        border-bottom: rem-calc(1) solid $text;
        text-align: center;
        border-radius: 0;

        &.val-filled {
          border-bottom: rem-calc(1) solid transparent;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .item-lable {
        margin-bottom: rem-calc(16);
      }
    }
  }

  .modal-footer {
    padding: 0px rem-calc(28) rem-calc(34);
    border-top: 0;

    .btn {
      width: 100%;
      margin: 0;
    }
  }

  .btn-blue {
    &:hover {
      color: $white;
      background-color: rgba($blueScale-1, 0.9);
      border-color: rgba($blueScale-1, 0.9);
    }
  }
}

.mini-cart {
  //position: absolute;
  //right: 0;
  //top: 100%;
  text-align: left;

  //box-shadow: 0 0 5px -2px #00000050;
  //&:before {
  //    content: '';
  //    position: fixed;
  //    width: 100%;
  //    height: 100%;
  //    background-color: rgba(0,0,0,0.5);
  //    left: 0;
  //    top: 127px;
  //    z-index: 1;
  //}
  .dialog {
    width: 635px;
    background-color: $white;
    padding: 23px;
    z-index: 2;
    position: relative;
    border-radius: 4px;
  }

  .mini-cart-header {
    h5 {
      color: $text;
    }
  }

  //.mini-cart-body {
  //    max-height: 474px;
  //    overflow: auto;
  //}
  .mini-cart-footer {
    padding-top: 18px;
  }

  .empty-cart {
    height: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-cart-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .sub-total,
  .shipping {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .label,
    .value {
      color: $text;
      font-size: 17px;
    }

    .label {
      padding-right: 5px;
    }

    .value {
      padding-left: 5px;
    }
  }

  .shipping {
    padding-bottom: 18px;
  }

  .sub-total {

    .label,
    .value {
      font-weight: 700;
      font-family: $sfProTextMedium;
    }
  }

  .item-img {
    width: 180px;
    min-width: 180px;
    background: #f5f5f5;
    padding: 15px;
  }

  .bag-item {
    display: flex;
    padding-top: 20px;
    padding-bottom: 28px;
    border-bottom: 1px solid #999999;

    .back-img,
    .front-img {
      width: calc(100% - rem-calc(40));
      height: calc(100% - rem-calc(40));
    }

    .card-img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: rem-calc(180);
      height: rem-calc(180);

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .item-detail {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-left: 22px;
  }

  .item-name,
  .quantity {
    margin-bottom: 30px;
  }

  .item-name {
    h6 {
      color: $text;
      font-family: $sfProTextMedium;
      font-size: 17px;
      font-weight: 700;
    }
  }

  .item-price {
    padding-left: 10px;
    margin-left: auto;

    span {
      display: flex;
    }
  }

  .regular-price {
    font-family: $sfProTextMedium;
  }

  .discount-price {
    color: #999999;
  }

  .action-list {
    display: flex;

    li {
      margin-right: rem-calc(5);
      padding-right: rem-calc(5);
      border-right: rem-calc(1) solid $grayScale-1;

      a {
        font-size: rem-calc(14);
        line-height: rem-calc(12);
        display: block;
        color: #999999;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
    }
  }
}

.choose-color {
  padding-top: 15px;
  padding-bottom: 15px;

  .label {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 18px;
    color: #1d1d1f;

    span {
      font-family: $sfProTextMedium;
      color: #999999;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .color-block {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .color-list {
    display: flex;
  }

  .color {
    margin-right: 16px;
    position: relative;

    &:last-child {
      margin-right: 0px;
    }

    .na {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 8px;
      line-height: 9px;
      color: #999999;
      transform: translate(-50%, -50%);
    }
  }

  .btn-color {
    border: 1px solid #707070;
    border-radius: 50%;
    height: 21px;
    width: 21px;
    position: relative;
    display: block;

    &.active {
      &:before {
        content: '';
        border: 1px solid #181818;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-4px, -4px);
      }
    }

    &.disabled,
    &:disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

/* The container */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 17px;
    width: 17px;
    z-index: 1;

    ~.checkmark {
      background-color: #ffffff;
    }

    &:checked~.checkmark {
      background-color: #c81712;
      border: 1px solid #c81712;
    }

    ~.checkmark {

      &:after,
      &:before {
        display: block;
      }
    }
  }

  .checkmark {

    &:before,
    &:after {
      background: white;
    }

    &:before {
      top: 7px;
      left: 4px;
      width: 2px;
      height: 5px;
      transform: rotate(-45deg);
    }

    &:after {
      top: 3px;
      left: 9px;
      width: 2px;
      height: 9px;
      transform: rotate(45deg);
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  border: 1px solid #e6e5e6;
}

.checkmark:after,
.checkmark:before {
  content: '';
  position: absolute;
  display: none;
}

.text-underline {
  text-decoration: underline;
}

.link-btn {
  &.btn {
    border: none;
    color: $blue-1;

    &:hover,
    &:active,
    &:focus {
      border: none;
      color: $blue-1;
    }
  }
}

.heading14 {
  font-family: $sfProTextMedium;
  font-size: rem-calc(14);
  line-height: rem-calc(22);
}

.heading18 {
  font-size: rem-calc(18);
  line-height: rem-calc(25);
  color: $blueScale-2;
  font-family: $sfProText;
  font-weight: 600;
  margin-bottom: rem-calc(14);
}

.heading20 {
  font-size: rem-calc(20);
  line-height: rem-calc(26);
}

.heading26 {
  font-size: rem-calc(26);
  line-height: rem-calc(32);
}

.zIndex9 {
  z-index: 9;
}

.zIndex99 {
  z-index: 99;
}

.checkout-item {
  width: rem-calc(180);
  width: 100%;
  min-width: rem-calc(150);
  height: rem-calc(190);

  .card-img {
    width: 100%;
    height: 100%;
    // background-color: #F7F7F5;
    // border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    object-fit: contain;
    max-width: 18.75rem;
    width: 100%;
    height: 100%;
  }

  .back-img,
  .front-img {
    width: calc(100% - rem-calc(40));
    height: calc(100% - rem-calc(40));
  }
}

.heading-with-circle-list {
  position: relative;
  padding-left: 34px;
  margin-bottom: 24px;

  span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
  }
}

.button-radio-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -12px -6px 0;

  &.w-33 {
    .form-check {
      width: 33.33%;
    }
  }

  .form-check {
    padding: 12px 6px 0;
    width: 25%;
  }
}

.heading12 {
  font-size: 12px;
  line-height: 16px;
}

.choose-sizes-wrap {
  .items {
    display: flex;
    margin: -8px -4px 0;
  }

  .size {
    padding: 4px 5px;
    width: 16.66%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .item-qty {
      width: 100%;
      display: inline-block;
      border: 1px solid $grayScale-3;
      padding: 8px 2px;
      border-radius: 4px;
      text-align: center;
    }
  }

  .size-type {
    text-transform: uppercase;
    margin-bottom: 4px;
  }
}

.min-width-auto {
  min-width: auto !important;
}

.quantity-row {
  margin: 0 -16px;
  padding: 7px 16px;

  &.active {
    background-color: rgba($grayScale-4, 0.7);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .quantity-controls {
    .btn {
      border-radius: 0;
      border-right: 1px solid $grayScale-3;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.bordered-list {
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-top: -8px;
  }

  .size {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 8px 0;
    border-right: 1px solid $grayScale-3;
  }
}

.quantity-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-control {
    padding: 4px;
    line-height: 1;
    height: auto;
    background-color: $transparent;
    width: rem-calc(120);
    min-width: initial;

    &.width-sm {
      width: 51px;
      text-align: center;
    }

    &:read-only {
      border-color: $transparent;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.pricing-details {
  display: flex;

  .heading12 {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  .text-decoration-line-through {
    color: $grayScale-2;
  }
}

.button-radio {
  position: relative;

  .form-check-label {
    width: 100%;
    padding: 4px 10px;
    text-align: center;
    border: 2px solid $grayScale-3;
    border-radius: 4px;
  }

  .form-check-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;

    &:checked {
      &~.form-check-label {
        border-color: $blue-1;
      }
    }
  }
}

.overlay {
  width: auto;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 10;
  opacity: inherit !important;
}

@media (max-width: 1199px) {
  .mini-cart {
    &:before {
      top: 127px;
    }

    //.mini-cart-body {
    //    max-height: calc(100vh - 330px);
    //}
    .dialog {
      width: 100%;
      height: calc(100vh - 137px);
      padding: 15px;
    }
  }
}

@media (max-width: 991px) {
  .custom-modal {
    .btn-close {
      right: calc(100% - 50px);
      top: -30px;
    }
  }

  .custom-container-fluid {
    padding-left: rem-calc(12);
    padding-right: rem-calc(12);
  }

  h1 {
    font-size: rem-calc(36);
    line-height: rem-calc(44);
  }

  h2 {
    font-size: rem-calc(32);
    line-height: rem-calc(40);
  }

  h3 {
    font-size: rem-calc(28);
    line-height: rem-calc(32);
  }

  .quantity-item {
    flex-wrap: wrap;

    .form-control {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .bordered-list {
    margin-top: 16px;
  }

  .quantity-row {
    .quantity-controls {
      .btn {
        width: 100%;
        text-align: center;
        border-right: none;
        border-bottom: 1px solid $grayScale-3;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding-top: rem-calc(132);
  }

  .mini-cart {
    width: calc(100% - 10px);

    &:before {
      top: 83px;
    }

    //.mini-cart-body {
    //    max-height: calc(100vh - 302px);
    //    overflow: auto;
    //}
    .dialog {
      width: 100%;
      height: calc(100vh - 93px);
      padding: 15px;
    }

    .item-detail {
      padding-left: 10px;
    }

    .item-name,
    .quantity {
      margin-bottom: 10px;
    }

    .item-name {
      h6 {
        font-size: 14px;
      }
    }

    .color {
      font-size: 14px;
    }

    .quantity {
      p {
        font-size: 14px;
      }
    }

    .item-price {
      span {
        font-size: 14px;
      }
    }

    .item-img {
      width: 80px;
      min-width: 80px;
      padding: 5px;
      align-self: flex-start;
    }

    .bag-item {
      padding-top: 15px;
      padding-bottom: 18px;
    }

    .sub-total,
    .shipping {

      .label,
      .value {
        font-size: 15px;
      }
    }
  }
}

.min-width-unset {
  min-width: unset;
}

//Text color variants
.text-disbaled {
  color: $grayScale-1;
}

.text-red {
  color: $red;
}

//Font weight
.font-weight-normal {
  font-weight: $regular;
}

.pac-container {
  z-index: 1500;
}

.link-modal-wrap {
  display: flex;
  // align-items: center;
  justify-content: space-between;

  .link-modal-left {
    width: calc(100% - 130px);

    input {
      width: 100%;
      padding: 6px 10px;
      border-radius: 5px 0 0 5px;
      border-right: none;
      height: 100%;
    }
  }

  .link-modal-right {
    width: 130px;

    .btn {
      width: 100%;
      border-radius: 0 5px 5px 0;
    }
  }
}