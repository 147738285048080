.layoutWrap {
    position: relative;
    background-color: #D0F4DF;
    height: 100vh; 
    width: 100%
}

.backBtnWrap {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 3;
}

.backBtn{
    border: none;
    outline: none;
    font-family: 'Inter', sans-serif;
    background: none;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    i {
        margin-right: 10px;
    }
}

.lottieWrap {   
    position: absolute;
    bottom: 0;
    width: 100%;
}