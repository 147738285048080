/* Colors */

$transparent: transparent;
$black: #000000;
$white: #ffffff;
$text: #1D1D1F;
$blueScale-1: #00437A;
$blueScale-2: #1E99F4;
$red: #C71E08;
$redHover: #c01611;
$grayScale-1: #999999;
$grayScale-2: #CCCCCC;
$grayScale-3: #EFEFEF;
$grayScale-4: #F7F7F5;
$grayScale-5: #707070;

$blue-1: #0072E3;

/*Font*/

$sfProText: 'SF Pro Text';
$sfProTextMedium: 'SF Pro Text Medium';
// $merriweather: 'Merriweather-Regular';
$merriweather: 'Merriweather-Heavy';
$heavy: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;