.custom-datepicker {
  &.with-icon {
    position: relative;

    &:after {
      content: '\e90b';
      font-family: 'icomoon';
      position: absolute;
      right: rem-calc(8);
      top: 50%;
      color: $text;
      font-size: rem-calc(16);
      transform: translateY(-50%);
    }
  }

  .react-datepicker__input-container {
    input {
      border: rem-calc(1) solid $grayScale-2;
      border-radius: rem-calc(4);
      padding: rem-calc(4) rem-calc(28) rem-calc(4) rem-calc(4);
    }
  }
}
