.table {
  tr {
    &.active {
      background-color: $grayScale-4;
    }
  }

  th {
    font-size: rem-calc(12);
    color: $grayScale-1;
  }

  td {
    font-size: rem-calc(14);
  }

  &.table-trip {
    thead {
      tr {
        background-color: $grayScale-4;
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background-color: $grayScale-4;
        }
      }
    }

    th {
      font-size: rem-calc(11);
      color: $text;
      border-bottom: 0;
    }

    td {
      font-size: rem-calc(11);
      color: $text;
      border-bottom: 0;
    }
  }
}

.table-overflow {
  overflow: auto;
  max-height: 166px;
}

.custom-table {
  border: rem-calc(1) solid $grayScale-2;
  width: 100%;
  // table-layout: fixed;

  &.striped-table {
    border: none;

    .checkout-item {
      margin-bottom: rem-calc(16);
    }

    h6 {
      font-family: $sfProText;
      font-weight: $bold;
      line-height: rem-calc(25);
      border-bottom: rem-calc(1) solid $grayScale-2;
      margin-bottom: rem-calc(20);
      color: $text;
    }

    // table-layout: auto;

    tr {
      &:nth-last-child(1) {
        td {
          padding: rem-calc(20) 0;
        }
      }

      th,
      td {
        background-color: $transparent;
        border-right: none;
        padding: 0;
      }

      td {
        vertical-align: top;

        &.pd-tb-sm {
          padding-top: rem-calc(10);
          padding-bottom: rem-calc(10);
        }

        &:nth-last-child(1) {
          padding-right: 0;
        }
      }

      &:nth-last-child(1) {

        td {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  tr {

    th,
    td {
      min-width: rem-calc(150);
      width: rem-calc(150);
      padding: rem-calc(8) rem-calc(24);
      border-bottom: rem-calc(1) solid $grayScale-2;
      border-right: rem-calc(1) solid $grayScale-2;
      white-space: nowrap;
    }

    th {
      background-color: $grayScale-4;

      &:nth-last-child(1) {
        border-right: none;
      }
    }

    // td {}
  }
}

.border-only {
  width: 100%;
  text-align: center;
  table-layout: fixed;

  tr {
    th {
      font-weight: $medium;
    }

    th,
    td {
      padding: rem-calc(5);
      border-right: rem-calc(1) solid $grayScale-2;
      border-bottom: rem-calc(1) solid $grayScale-2;
      font-size: rem-calc(14);
      color: $text;
      font-family: $sfProText;

      &:nth-last-child(1) {
        border-right: none;
      }

    }

    &:nth-last-child(1) {
      td {
        border-bottom: none;
      }
    }
  }
}

// Pankaj Pratap Singh (March 31 2023)

table.loading tbody {
  position: relative;
}

table.loading tbody:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  content: 'Loading...';
}