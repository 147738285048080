@function rem-calc($value) {
  $remValue: ($value / 16)+rem;
  @return $remValue;
}

%font20 {
  font-family: $merriweather;
  color: $text;
  font-size: rem-calc(20);
  line-height: rem-calc(24);
}

%subTitle {
  color: $grayScale-1;
  font-weight: $medium;
  font-size: rem-calc(17);
  line-height: rem-calc(25);
}

%subTitle,
%font16,
%font14,
%font12 {
  font-family: $sfProText;
  color: $text;
}

%font16 {
  font-size: rem-calc(16);
  line-height: rem-calc(25);
}

%font14 {
  font-size: rem-calc(14);
  line-height: rem-calc(18);
}

%font12 {
  font-size: rem-calc(12);
  line-height: rem-calc(16);
}

@mixin borderRadius ($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin transition ($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
}

@mixin transform ($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
}

@mixin rotate($rotate) {
  transform: rotate($rotate);
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
}

@mixin boxShadow ($shadow) {
  box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
}

@mixin opacity($value) {
  $IEvalue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=" +$IEvalue+")";
  filter: alpha(opacity=$IEvalue);
}

@mixin size($circleSize) {
  min-width: $circleSize;
  width: $circleSize;
  min-height: $circleSize;
  max-height: $circleSize;
  height: $circleSize;
  line-height: $circleSize;
  @include borderRadius (50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

%listRedDot {
  ul {
    li {
      position: relative;
      padding-left: rem-calc(18);
      padding-bottom: rem-calc(18);
      font-size: rem-calc(16);
      font-family: $sfProTextMedium;

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: rem-calc(7);
        height: rem-calc(9);
        width: rem-calc(9);
        border-radius: 50%;
        background-color: $red;
      }
    }
  }
}

%btnBig {
  min-width: rem-calc(300);
  font-size: rem-calc(19);
  line-height: rem-calc(26);
  padding: rem-calc(17) rem-calc(25);
  font-family: $sfProTextMedium;

  @media (max-width: 767px) {
    padding: rem-calc(10) rem-calc(25);
    font-size: rem-calc(16);
    line-height: rem-calc(22);
  }
}

%btnMedium {
  min-width: rem-calc(210);
  font-size: rem-calc(16);
  line-height: rem-calc(25);
  padding: rem-calc(8) rem-calc(25);
}

%btnSmall {
  min-width: rem-calc(210);
  font-size: rem-calc(12);
  line-height: rem-calc(16);
  padding: rem-calc(8) rem-calc(25);
}

$buttonOutlineConfig: 'primary'$transparent $blueScale-2 $white $grayScale-2,
  'secondary'$transparent $grayScale-1 $white $grayScale-2,
  'blue'$transparent $blueScale-1 $white $grayScale-2;

@each $var in $buttonOutlineConfig {
  .btn-outline-#{nth($var, 1)} {
    background: #{nth($var, 2)};
    border-color: #{nth($var, 3)};
    color: #{nth($var, 3)};
    @extend %btnBig;

    &.btn-small {
      @extend %btnSmall;
    }

    &.btn-medium {
      @extend %btnMedium;
    }

    &:hover,
    &:focus {
      background: #{nth($var, 3)};
      border-color: #{nth($var, 3)};
      color: #{nth($var, 4)};
      @include boxShadow(none);
    }

    &.disabled,
    &[disabled] {
      background: #{nth($var, 5)};
      border-color: #{nth($var, 5)};
      color: #{nth($var, 4)};
      pointer-events: none;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          background: #{nth($var, 3)};
          border-color: #{nth($var, 3)};
          color: #{nth($var, 4)};
          @include boxShadow(none);

          &:focus {
            background: #{nth($var, 3)};
            border-color: #{nth($var, 3)};
            color: #{nth($var, 4)};
            @include boxShadow(none);
          }
        }

        &.active {
          background: #{nth($var, 3)};
          border-color: #{nth($var, 3)};
          color: #{nth($var, 4)};
        }
      }
    }
  }
}

$buttonSolidConfig: 'red'$red $white $white $grayScale-2 $redHover $white,
  'light'$white $blueScale-1 $white $grayScale-2 $redHover $white,
  'blue'$blueScale-1 $white $white $grayScale-2 $blueScale-1 $white;

@each $var in $buttonSolidConfig {
  .btn-#{nth($var, 1)} {
    background-color: #{nth($var, 2)};
    border-color: #{nth($var, 2)};
    color: #{nth($var, 3)};
    @extend %btnBig;

    @media (max-width: 767px) {
      min-width: auto;
    }

    &.btn-small {
      @extend %btnSmall;
    }

    &.btn-medium {
      @extend %btnMedium;
    }

    &:first-child:hover,
    &:not(.btn-check)+.btn:hover,
    &:hover,
    &:focus {
      @include boxShadow(none);
      background-color: #{nth($var, 6)};
      border-color: #{nth($var, 6)};
      color: #{nth($var, 7)};
    }

    &.disabled,
    &[disabled] {
      background-color: #{nth($var, 5)};
      border-color: #{nth($var, 4)};
      color: #{nth($var, 4)};
      pointer-events: none;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          background-color: #{nth($var, 2)};
          border-color: #{nth($var, 2)};
          color: #{nth($var, 3)};
          @include boxShadow(none);

          &:focus {
            background-color: #{nth($var, 2)};
            border-color: #{nth($var, 2)};
            color: #{nth($var, 3)};
            @include boxShadow(none);
          }
        }

        &.active {
          background-color: #{nth($var, 2)};
          border-color: #{nth($var, 2)};
          color: #{nth($var, 3)};
        }
      }
    }
  }
}