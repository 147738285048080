.banner-cntr {
    background-color: $grayScale-3;
    position: relative;
    img {
        margin-left: auto;
        width: 100%;
    }
}
.banner-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.banner-text {
    padding-left: rem-calc(56);
    padding-right: rem-calc(56);
    .main-heading {
        padding-bottom: rem-calc(20);
        h1 {
            font-size: rem-calc(41);
            line-height: rem-calc(50);
        }
    }
    .text {
        padding-bottom: 35px;
        p {
            font-size: rem-calc(20);
            line-height: rem-calc(29);
        }
    }
}

@media (max-width: 991px) {
    .banner-box {
        position: static;
        text-align: center;
        display: block;
    }
    .banner-text {
        padding: rem-calc(25);
        .main-heading {
            h1 {
                font-size: rem-calc(30);
                line-height: rem-calc(38);
            }
        }
    }
}