.card-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: rem-calc(35);

  .card {
    width: calc(100% / 4 - 20px);
    border: 0;
    border-radius: 0;
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
    margin-bottom: rem-calc(48);
  }

  .card-thumb {
    .card-img {
      img {
        max-width: none;
        object-fit: cover;
      }
    }
  }

  .card-img {
    height: rem-calc(415);
    background-color: $grayScale-4;
    border-radius: rem-calc(4);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .text-overlay {
      position: absolute;
      text-align: left;
      right: 0;
      left: 20px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: rem-calc(35);

      .tees-list ul {
        list-style: disc;
        padding-left: rem-calc(32);

        li {
          color: $white;
          text-align: left;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: rem-calc(10);
        font-weight: 700;
      }

      p {
        font-size: rem-calc(13);
        line-height: rem-calc(20);
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: $white;
      }
    }

    img {
      object-fit: contain;
      max-width: rem-calc(300);
      width: 90%;
      height: 90%;
    }

    a {
      transition: all 0.3s ease-in-out;
      display: block;
    }

    // &:hover {
    //     a {
    //         transform: scale(2.9);
    //     }
    // }
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;

    .product-info {
      display: flex;
      justify-content: space-between;

      * {
        font-size: rem-calc(14);
        line-height: rem-calc(18);
      }

      .label,
      .value {
        width: 50%;
        display: block;
        font-size: rem-calc(12);
        line-height: rem-calc(16);
      }

      .label {
        padding-right: rem-calc(5);
      }

      .value {
        color: $grayScale-1;
        padding-left: rem-calc(5);
        text-align: right;
      }
    }
  }
}

@media (max-width: 1199px) {
  .card-list {
    .card {
      width: calc(100% / 3 - 20px);
    }
  }
}

@media (max-width: 991px) {
  .card-list {
    .card {
      width: calc(100% / 2 - 20px);
    }

    .card-img {
      .text-overlay {
        padding: rem-calc(15);
      }
    }
  }
}

@media (max-width: 767px) {
  .filter-list {
    .list {
      margin-left: rem-calc(15);

      &:first-child {
        margin-left: 0;
      }

      [class^=icon-],
      [class*=" icon-"] {
        font-size: rem-calc(20);
        line-height: rem-calc(20);
        margin-left: 0;
      }

      .list-item {
        font-size: 12px;
        display: flex;
        line-height: 1.25rem;
        white-space: nowrap;
      }

      &.show {
        .icon-location {
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .card-list {
    .card {
      width: calc(100% / 1 - 20px);
    }
  }
}
