.header-cntr,
.sub-header-cntr {
  padding: rem-calc(20) rem-calc(30);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $white;
  border-bottom: 1px solid $grayScale-2;
}

.sub-header-cntr {
  position: static;

  .heading-section {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $text;
      font-weight: 700;
    }
  }
}

.brand-logo {
  a {
    width: 100%;
    display: inline-block;

    img {
      margin: 0 auto;
    }
  }
}

.navbar-expand-lg {
  .menu-list {
    .nav-link {
      padding-left: rem-calc(20);
    }

    .nav-item {
      margin-left: rem-calc(10);

      &:first-child {
        margin-left: rem-calc(0);
      }

      .nav-link {
        @include transition(border-bottom 0.5s ease-in-out 0s);
        border-bottom: rem-calc(2) solid $transparent;
        padding: rem-calc(3) rem-calc(6);

        &:hover,
        &.active {
          border-bottom: rem-calc(2) solid $red;
        }
      }
    }
  }
}

.header-login {
  text-align: right
}

.logo-cart-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .shooping-cart {
    display: inline-block;
    position: relative;
    cursor: pointer;

    .shopping-icon {
      color: #000;
      font-size: 30px;
      line-height: 30px
    }

    .cart-count {
      background-color: #688cb5;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      font-weight: $sfProTextMedium;
      padding: 3px;
      min-width: 20px;
      text-align: center;
      display: inline-block;
      position: absolute;
      right: -4px;
      bottom: 0;
    }
  }
}

.dropdown-header {
  padding: 10px 0 10px;
  margin: 0 16px 16px;
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);

  .dropdown-header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-text {
    font-family: $sfProTextMedium;
    color: #000
  }

  .cross-btn {
    border: none;
    background: none;
    font-size: 14px;
    line-height: 14px;
  }
}

.login-form {
  @extend %font12;

  .has-icon {
    position: relative;

    .symbol {
      position: absolute;
      left: rem-calc(12);
      top: 50%;
      transform: translateY(-50%);
      font-size: rem-calc(18);
      color: $blueScale-1;
    }

    .form-control {
      padding-left: rem-calc(35);
    }
  }

  .form-check {
    display: flex;
    align-items: center;
  }

  [type="checkbox"] {
    height: rem-calc(18);
    width: rem-calc(18);
    margin-right: rem-calc(10);

    &:focus {
      box-shadow: none;
    }
  }
}

.login {
  .login-item {
    position: relative;

    .item {
      @include transition(all 0.5s ease-in-out 0s);
      border-bottom: rem-calc(2) solid $transparent;
      padding: rem-calc(3) rem-calc(8);
      display: inline-block;

      &.btn {
        border-top: 0;
        border-right: 0;
        border-left: 0;

        &.nowrap {
          white-space: nowrap;
        }
      }

      &:hover,
      &.active {
        border-bottom: rem-calc(2) solid $red;
      }
    }

    &.active {
      .login-dropdown {
        right: 0;
      }

      .item {
        border-bottom: rem-calc(2) solid $red;
      }
    }
  }

  .login-dropdown {
    @include transition(all 0.5s ease-in-out 0s);
    width: 261px;
    background-color: $white;
    padding: rem-calc(25);
    position: absolute;
    right: calc(-100% - 1000px);
    top: calc(100% + 2px);
    z-index: 13;
    text-align: left;

    input {
      border-radius: 0;
      @extend %font12;
    }
  }
}

.menu-toggle {
  border: 0;
  background-color: transparent;
  font-size: rem-calc(30);
  color: #0a82ff;
}

.filter-list {
  display: flex;
  justify-content: flex-end;

  .list {
    margin-left: rem-calc(50);
    position: relative;

    .list-item {
      font-size: rem-calc(14);
      font-weight: $light;
      display: flex;
      align-items: center;

    }

    #Ellipse_4,
    #Ellipse_5,
    #Ellipse_6 {
      transition: 0.3s all;
    }

    .drop-title {
      border-bottom: rem-calc(1) solid $white;
    }

    &:hover {
      .drop-title {
        border-color: $red;
      }

      #Ellipse_4 {
        transform: translate(0, 9.825px);
      }

      #Ellipse_5 {
        transform: translate(4.81px, 3px);
      }

      #Ellipse_6 {
        transform: translate(9.621px, 9.825px);
      }

      .location {
        transform: rotateY(180deg);
      }
    }

    &:first-child {
      margin-left: rem-calc(0);
    }

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: rem-calc(16);
      color: $red;
      margin-right: rem-calc(10);
    }

    &.show {

      .icon-filter,
      .icon-location {
        display: inline-block;
        font-size: 12px;
        height: 16px;
        width: 16px;
        text-align: center;
        line-height: 16px;

        &:before {
          content: "\e907";
        }
      }

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        height: 2px;
        background-color: #c81712;
        width: calc(100% - 30px);
      }
    }
  }

  .sub-header-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    padding: 13px 16px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 5px -3px rgb(0 0 0 / 70%);
    z-index: 10;
    background-color: #ffffff;
    min-width: 210px;
    margin-top: rem-calc(14);
    border: 0;
  }
}

.disabled-product {
  opacity: 0.4;
  pointer-events: none;
}


.login-popup-wrap {
  position: relative;

  .js-login-link-item {
    border-radius: 0;
  }
}

.login-popup-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: $white;
  padding: rem-calc(22) rem-calc(24);
  min-width: rem-calc(250);
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  .form-group {
    position: relative;
    margin-bottom: rem-calc(17);

    span {
      position: absolute;
      left: rem-calc(15);
      top: 50%;
      transform: translateY(-50%);
      color: $blueScale-1;
    }

    .form-control {
      padding: rem-calc(8) rem-calc(8) rem-calc(8) rem-calc(37);
      border: rem-calc(1) solid $grayScale-2;
      border-radius: 0;
      font-size: rem-calc(12);
      line-height: 1.5;
    }
  }

  .forget-password-wrap {
    a {
      width: 100%;
      display: inline-block;
      font-size: rem-calc(11);
      color: $blueScale-1;
      text-align: left;
    }
  }

  .btn-controls {
    padding-top: rem-calc(16);

    .btn {
      font-size: rem-calc(12);
      line-height: 1.5;
      padding: 9px;
    }
  }
}

.password-view-btn {
  position: absolute;
  right: 10px;
  top: 7px;
  background: none;
  border: none;

  [class^="icon-"],
  [class*=" icon-"] {
    position: static !important;
  }
}

@media (max-width: 991px) {
  .brand-logo {
    text-align: center;
  }

  .navbar-expand-lg {
    position: relative;

    .menu-list {
      @include transition(all 0.5s ease-in-out 0s);
      // position: absolute;
      position: fixed;
      top: 0;
      left: -100%;
      background-color: $white;
      width: rem-calc(230);
      border-radius: 0 10px 10px 0;
      height: 100vh;
      overflow-x: auto;
      z-index: 20;


      .menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 10px 10px;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);

        .menu-text {
          font-size: rem-calc(20);
          line-height: rem-calc(28);
          color: $black;
          font-family: $sfProTextMedium;
          margin-right: 12px;
        }

        .cross-btn {
          border: none;
          background: none;
          font-size: 14px;
          line-height: 22px;
          color: $black;
        }
      }

      .nav-item {
        margin-left: 0;
        margin-top: 2px;

        &:first-child {
          padding-left: rem-calc(6);
        }

        &.active {
          .nav-link {
            background-color: rgba($color: $black, $alpha: 0.1);
            border: 0 !important;
          }
        }
      }

      .nav-link {
        padding: rem-calc(4) rem-calc(10) !important;
        margin-bottom: 0 !important;
        border: 0 !important;
        font-family: $sfProTextMedium !important;

        &:hover {
          background-color: rgba($color: $black, $alpha: 0.1);
          border: 0 !important;
        }
      }

      &.show-menu {
        left: 0;
      }

      .after-login {
        .login {
          align-items: flex-start;
          width: 100%;
        }

        .js-login-item {
          width: 100%;
          margin-top: 2px;

          &:first-child {
            margin-top: 0;
          }
        }

        .js-login-link-item {
          padding: 4px 10px !important;
          width: 100%;
          text-align: left;
          border: 0 !important;

          .js-login-link-item1 {
            padding: 0;
          }

          &:hover {
            background-color: rgba($color: $black, $alpha: 0.1);
            border: 0 !important;
          }
        }
      }
    }
  }

  .menu-toggle:focus,
  .menu-toggle:active {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .login-popup-menu {
    padding: rem-calc(10);
    border-radius: 0 0 rem-calc(10) rem-calc(10);
  }

  .parent-mobile-dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;

      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 4px;
      }

      .down-arrow {
        margin-left: 4px;
      }
    }

    .custom-dropdown {
      .sub-header-dropdown {
        position: fixed !important;
        width: 100%;
        inset: auto 0 0 0 !important;
        // bottom: 0;
        // left: 0;
        // right: 0;
        max-height: unset;
        height: calc(100vh - 132px);
        border: none;
        border-radius: 12px 12px 0 0;
        transition: all 0.3s linear;
        transform: translateY(100%) !important;
        display: block !important;
        z-index: 104 !important;
        overflow: unset;

        .dropdown-list-cntr {
          height: calc(100% - 53px);
          overflow-y: auto;
        }

        &.show {
          transform: translateY(0) !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .header-cntr,
  .sub-header-cntr {
    padding: rem-calc(12);
  }

  .header-cntr {
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);
  }

  .brand-logo {
    img {
      max-height: 60px;
    }
  }

  .navbar-expand-lg {
    .menu-list {
      .nav-item {
        margin-left: rem-calc(0);

        // .nav-link {
        //   padding-left: rem-calc(0);
        //   padding-right: rem-calc(0);
        // }

        &:first-child {
          margin-left: rem-calc(0);
          padding-left: rem-calc(0);
        }
      }
    }
  }
}