.loaderFullPage{
  position: fixed;
  z-index: 1300;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.3);
  color: #fff;
}

.loadingText{
  font-size: 1.25rem;
  font-weight: 500;

}