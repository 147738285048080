.contact-us-box {
  margin: 48px 0;

  .contact-us-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-us-left {
    width: calc(100% - rem-calc(513));

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contact-us-right {
    background-color: $blueScale-1;
    width: rem-calc(513);
    padding: rem-calc(20);

    .email-box {
      font-size: rem-calc(18);
      font-family: $sfProTextMedium;
      line-height: rem-calc(24);
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(50);
      text-decoration: underline;
      display: inline-block;
      color: $white;

      .icon {
        font-size: rem-calc(27);
        vertical-align: middle;
        margin-right: rem-calc(18);
      }
    }

    .mx-347 {
      max-width: rem-calc(347);
      margin: 0 auto;
    }

    h1,
    p,
    h2 {
      font-family: $sfProText;
      color: $white;
    }

    h1 {
      font-family: $merriweather;
      font-size: rem-calc(45);
      font-weight: $regular;
      text-align: center;
      margin-bottom: rem-calc(24);
    }

    h2 {
      color: $white;
      font-weight: $regular;
      font-size: rem-calc(16);
      line-height: rem-calc(24);
    }

    .btn {
      margin: rem-calc(20) auto rem-calc(80);
      display: block;
      font-size: rem-calc(18);
      line-height: rem-calc(24);
    }
  }
}

@media (max-width: 1399px) {
  .contact-us-box {
    .contact-us-left {
      width: calc(100% - rem-calc(413));
    }

    .contact-us-right {
      width: rem-calc(413);
    }
  }
}