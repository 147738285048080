.account-box {
  display: flex;
  padding-top: rem-calc(37);
  padding-bottom: rem-calc(90);

  h3 {
    font-family: $sfProText;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    font-weight: 400;
    color: $text;
    margin-bottom: rem-calc(28);
  }

}

.account-tab-content {
  padding-left: 315px;
  width: calc(100% - rem-calc(100));
}

.account-side-nav {
  width: rem-calc(205);
  margin: 0 rem-calc(50) 0 rem-calc(60);
  position: fixed;
  left: 0;

  ul {
    margin-bottom: rem-calc(40);

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    li {
      margin-bottom: rem-calc(12);

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        padding-left: rem-calc(4);
        border-left: rem-calc(2) solid $white;

        &.active {
          border-color: $red;
        }
      }
    }
  }
}

.mx-1000 {
  max-width: rem-calc(1000);
}

.account-main-content {
  width: 100%;
  // max-width: rem-calc(1000);
  margin-left: rem-calc(50);

  &.order-history-width {
    max-width: rem-calc(880);
  }

  .btn {
    min-width: rem-calc(78);
  }

  .add-new-btn {
    font-size: rem-calc(14);
    line-height: 2;
  }
}

.form-wrap {
  margin-bottom: rem-calc(26);

  label {
    font-weight: 600;
    display: inline-block;
    margin-bottom: rem-calc(6);
  }

  .note-text {
    font-size: rem-calc(12);
    display: inline-block;
    margin-bottom: rem-calc(7);
    color: $grayScale-1;
    line-height: rem-calc(15);
  }

  .two-inputswrap {
    display: flex;

    .input-wrap {
      width: 50%;
      margin-right: rem-calc(8);

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .form-control {
        width: 100%;
      }
    }

  }
}

.buyer-details {
  .headingbuyer {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    font-weight: 600;

    .default {
      color: $blueScale-2;
      font-size: rem-calc(12);
      font-weight: 700;
      margin-left: rem-calc(8);
    }
  }

  .buyer-email {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }

  .buyer-addr {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }

  .buyer-detials-controls {
    display: flex;

    .btn {
      position: relative;
      font-size: rem-calc(12);
      line-height: rem-calc(12);
      min-width: auto;
      padding: 0 rem-calc(8) 0 0;
      margin-right: rem-calc(8);
      color: $grayScale-1;

      &:hover,
      &:focus {
        border-color: $transparent;
      }

      &:nth-last-child(1) {
        &:after {
          content: none;
          margin-right: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        width: rem-calc(1);
        background-color: $grayScale-1;
      }
    }
  }
}

.buyer-location-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  .location-item {
    padding: 5px;
  }
}

.address-wrap {
  p {
    font-size: rem-calc(14);
  }
}


.search-address-wrap {
  display: flex;
  align-items: center;
  position: relative;

  .form-control {
    line-height: 1.5;
    height: rem-calc(38);
    border-radius: 0.375rem 0 0 0.375rem;
    padding-right: 2.75rem;

    &:focus {
      box-shadow: none;
    }
  }

  .search-btn {
    line-height: 1.5;
    height: 38px;
    border-radius: 0 0.375rem 0.375rem 0;
  }

  .clear-btn {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 1;
    padding: 4px;
  }
}

ul {
  &.address-result-list {
    margin-top: rem-calc(24);

    li {
      padding: 10px 0;
      border-bottom: 1px solid $grayScale-2;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }

  }
}

.search-address-suggestions {
  background: white;
  position: absolute;
  width: 100%;
  top: 40px;
  border: 1px solid #ced4da;
  border-radius: 0 0 8px 8px;

  ul {
    overflow: auto;
    max-height: rem-calc(163);
    padding: 0 rem-calc(12);

    li {
      &:nth-last-child(1) {
        border-radius: 0 0 rem-calc(8) rem-calc(8);
      }
    }
  }
}

.account-wrap {
  min-height: calc(100vh - 245px - 58px - 128px);

  .nav {
    .nav-link {
      font-size: rem-calc(16);
      color: $text;
      padding: rem-calc(7) 0;

      &.active {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -6px;
          transform: translateY(-50%);
          height: 20px;
          width: 2px;
          background-color: $red;
          border-radius: 10px;

        }

        background-color: $transparent;
      }

      &.inactive {
        border-color: transparent;
      }
    }
  }
}