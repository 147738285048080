// @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

// @import url('https://allfont.net/allfont.css?fonts=merriweather-heavy');

@font-face {
  font-family: 'SF Pro Text Medium';
  src: url('../fonts/text-fonts/SFProText-Medium.eot');
  src: url('../fonts/text-fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-fonts/SFProText-Medium.woff2') format('woff2'),
    url('../fonts/text-fonts/SFProText-Medium.woff') format('woff'),
    url('../fonts/text-fonts/SFProText-Medium.ttf') format('truetype'),
    url('../fonts/text-fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/text-fonts/SFProText-Regular.eot');
  src: url('../fonts/text-fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-fonts/SFProText-Regular.woff2') format('woff2'),
    url('../fonts/text-fonts/SFProText-Regular.woff') format('woff'),
    url('../fonts/text-fonts/SFProText-Regular.ttf') format('truetype'),
    url('../fonts/text-fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather-Regular';
  src: url('../fonts/text-fonts/Merriweather-Regular.eot');
  src: url('../fonts/text-fonts/Merriweather-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-fonts/Merriweather-Regular.svg#Merriweather-Regular') format('svg'),
    url('../fonts/text-fonts/Merriweather-Regular.ttf') format('truetype'),
    url('../fonts/text-fonts/Merriweather-Regular.woff') format('woff'),
    url('../fonts/text-fonts/Merriweather-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Merriweather-Heavy';
  src: url('../fonts/text-fonts/Merriweather-Heavy.eot');
  src: url('../fonts/text-fonts/Merriweather-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-fonts/Merriweather-Heavy.svg#Merriweather-Heavy') format('svg'),
    url('../fonts/text-fonts/Merriweather-Heavy.ttf') format('truetype'),
    url('../fonts/text-fonts/Merriweather-Heavy.woff') format('woff'),
    url('../fonts/text-fonts/Merriweather-Heavy.woff2') format('woff'),
    url('../fonts/text-fonts/Merriweather-Heavy.otf') format('woff2');
}